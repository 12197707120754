import React from "react"
import Intro from "../components/Manifest/Intro"
import AgainstCovidSection from "../components/Manifest/AgainstCovidSection"
import ReasonsSection from "../components/Manifest/ReasonsSection"
import SEO from "../components/Seo"


const IndexPage = () => {
  return (
    <>
      <SEO
        title={`Nuestro manifiesto`}
      />
      <Intro />
      <AgainstCovidSection/>
      <ReasonsSection/>
    </>
  )
}

export default IndexPage
