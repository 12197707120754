import React from "react";
import { MaxWidth, Columns, Column, Box, Text } from "@sqymagma/elements";
import Section from "../Section";
import { Paragraph } from "../Textstyles/Styles";
import BackgroundImage from "gatsby-background-image"
import { useStaticQuery, graphql } from "gatsby"


export default () => {
  const imageData = useStaticQuery(graphql`
    query {
      fileName: file(relativePath: { eq: "overlay-manifest-section_2.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)


  return (
    <Box bg="brand07" position="relative">
      <Box
        position="absolute"
        top="0" left="0" right="0" bottom="0"
        display={["none", null, "block"]}
      >
        <BackgroundImage 
          fluid={imageData.fileName.childImageSharp.fluid}
          style={{
            width:"100vw",
            height: "100%",
            backgroundSize: "cover",
            backgroundPosition: "center center",
          }}
        />
      </Box>

      <Section position="relative" py={["m", "xl"]}>
        <MaxWidth>
          <Box
            px={[0, null, null, "l", null, "xxl"]}
            py={[0, null, null, "xl", null, "xxl"]}
          >
            <Text
              as="h2"
              textStyle="xxxl-bold"
              color="text06"
              mb={[0, null, "l"]}
            >
              Secuoyas in fight against COVID·19
            </Text>

            <Columns flexDirection={["column", null, "row"]}>
              <Column width={[1, null, 1 / 3]} mb="l">
                <Box
                  width={["50%", "75%"]}
                  css={`
                     {
                      transform: rotate(-15deg);
                    }
                  `}
                  mx="auto"
                  maxWidth="300px"
                >
                  <object
                    type="image/svg+xml"
                    data="/images/anim/against-covid-badge.svg"
                    aria-label={"Secuoyas contra el Covid"}
                  />
                </Box>
              </Column>
              <Column width={[1, null, 2 / 3]}>
                <Paragraph
                  as="p"
                  textStyle="bodyTextBig"
                  mb="m"
                  color={["text01", null, "text06"]}
                >
                  En{" "}
                  <Text color={["brand01", null, "brand04"]} fontWeight="600">
                    Secuoyas
                  </Text>{" "}
                  estamos preparados y afrontamos esta crisis como un desafío
                  sin precedentes: nos estamos movilizando para estar más unidos
                  que nunca, trabajar con la mayor normalidad posible y
                  colaborar con la comunidad para que la información llegue de
                  manera clara y ayude en la toma de decisiones.
                </Paragraph>
                <Paragraph
                  as="p"
                  textStyle="bodyTextBig"
                  mb="m"
                  color={["text01", null, "text06"]}
                >
                  Desde que empezó el confinamiento, uno de nuestros equipos
                  está trabajando en un
                  <Text color={["brand01", null, "brand04"]} fontWeight="600">
                    {" "}
                    proyecto de visualización de datos
                  </Text>{" "}
                  con el objetivo de ampliar los recursos públicos disponibles
                  sobre el COVID-19.
                </Paragraph>
                <Paragraph
                  as="p"
                  textStyle="bodyTextBig"
                  mb="m"
                  color={["text01", null, "text06"]}
                >
                  Llevan semanas organizando y digitalizando informes y datos, con el propósito de construir un espacio neutro y fiable, donde el acceso a la información sea lo más rápido y sencillo posible.
                </Paragraph>
              </Column>
            </Columns>
          </Box>
        </MaxWidth>
      </Section>
    </Box>
  );
};
