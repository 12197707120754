import React from "react";
import { MaxWidth, Column, Box, Text, Flex } from "@sqymagma/elements";
import Section from "../Section";
import { Paragraph } from "../Textstyles/Styles";
import { useInView } from "react-intersection-observer";

export default () => {
  return (
    <Box bg="brand07">
      <Section position="relative" py={["m", "xl"]}>
        <MaxWidth>
          <Box
            px={[0, null, null, "l", null, "xxl"]}
            py={[0, null, null, "xl"]}
          >
            <Text as="h3" textStyle="xxxl-bold" color="text06" mb="l">
              Esta iniciativa tiene su origen en tres razones importantes:
            </Text>
          </Box>
          <RemoteWork />
          <InformationAccess />
          <DecissionMaking />
        </MaxWidth>
      </Section>
    </Box>
  );
};

export const RemoteWork = (_) => (
  <Box
    px={[0, null, null, "l", null, "xxl"]}
    minHeight={["none", null, null, "100vh"]}
  >
    <Flex
      minHeight={["none", null, null, "50vh"]}
      width={1}
      flexDirection={["column-reverse", null, null, "row"]}
    >
      <Column
        width={[1, null, null, 1 / 3]}
        mt={["-25%", "-30%", "0%", "auto"]}
      >
        <Box
          width="90%"
          maxWidth={["250px", null, "300px", "350px"]}
          display={["flex", null, "none", "flex"]}
        >
          <object
            type="image/svg+xml"
            data="/images/anim/remote-work-guy.svg"
            aria-label={"Remote work girl"}
          />
        </Box>
      </Column>
      <Column
        width={[1, null, null, 2 / 3]}
        pb={["m", "l", "m", "xxl"]}
        position="relative"
      >
        <Text as="h2" textStyle="xxxl-bold" color="text04" mb="l">
          El teletrabajo en Secuoyas
        </Text>
        <Paragraph
          as="p"
          textStyle="bodyTextBig"
          mb="m"
          color="text01"
          width={[1, null, null, "80%"]}
        >
          El teletrabajo forma parte de nuestra cultura de empresa desde hace
          una década, y desde entonces, tratamos de que la conciliación sea una
          realidad y no sólo una teoría. Esta flexibilidad nos ha permitido
          formar un equipo humano que proviene desde distintos puntos de la
          Península: Segovia, Cuenca, Valencia, Valladolid…
        </Paragraph>
        <Paragraph
          as="p"
          textStyle="bodyTextBig"
          mb="m"
          color="text01"
          width={[1, null, null, "80%"]}
        >
          A medida que el COVID-19 se ha ido extendiendo por todo el territorio
          nacional, ha surgido la necesidad de tener información actualizada de
          cada una de las zonas en las que residen nuestros compañeros con la
          finalidad de poder ayudarles y tomar medidas a tiempo.
        </Paragraph>
        <Box
          position={["relative", null, null, "absolute"]}
          right={["5%", null, null, "-15%"]}
          top={"0"}
          width="35%"
          maxWidth={"200px"}
          css={`
             {
              float: right;
            }
          `}
          display={["block", null, "none", "flex"]}
        >
          <object
            type="image/svg+xml"
            data="/images/anim/remote-work-girl.svg"
            aria-label={"Remote work girl"}
          />
        </Box>
      </Column>
    </Flex>
  </Box>
);

export const InformationAccess = (_) => {
  const [svgAnimRef, svgAnimInView] = useInView({
    rootMargin: "100px 0px",
    triggerOnce: true,
  });

  return (
    <Flex
      flexDirection={["column-reverse", null, "column"]}
      position="relative"
      pt={["xl", null, 0]}
      pb={["l", null, 0]}
    >
      <Box
        width={1}
        textAlign="center"
        my="m"
        ref={svgAnimRef}
        height={["100%", null, "750px"]}
      >
        <Box display={["none", null, "flex"]}>
          {svgAnimInView && (
            <object
              type="image/svg+xml"
              data="/images/anim/information-access.svg"
              aria-label={"Remote work girl"}
            />
          )}
        </Box>
        <Box display={["flex", null, "none"]} nmt="m">
          {svgAnimInView && (
            <object
              type="image/svg+xml"
              data="/images/anim/information-access-mobile.svg"
              aria-label={"Remote work girl"}
            />
          )}
        </Box>
      </Box>
      <Box
        pr={[0, "s", null, null, "l", "xxl"]}
        pl={[0, null, null, "xl", "xxl"]}
        position={["relative", null, "absolute"]}
        bottom="0"
        right="0"
      >
        <Column
          width={[1, null, 2 / 3, 3 / 4, null, 2 / 3]}
          ml="auto"
          pb={["0", null, "21vw", null, "18vw", "10vw"]}
        >
          <Text as="h2" textStyle="xxxl-bold" color="text04" mb="l">
            El acceso a la información
          </Text>
          <Paragraph
            as="p"
            textStyle="bodyTextBig"
            mb={[0, null, "m"]}
            color="text01"
          >
            A nivel europeo contamos multitud de fuentes de información que nos
            proporcionan datos útiles y fiables. Pero a medida que reducimos el
            campo de estudio,{" "}
            <strong>
              el acceso a la información a nivel estatal o autonómico, es más
              complicado
            </strong>
            . Estas limitaciones ha sido otro de los grandes estímulos para
            comenzar este proyecto, con el propósito de eliminar barreras de
            comunicación.
          </Paragraph>
        </Column>
      </Box>
    </Flex>
  );
};

export const DecissionMaking = (_) => (
  <Flex
    px={[0, null, null, "l", null, "xxl"]}
    height={["100%", null, "100vh"]}
    alignItems="center"
    justifyContent="center"
  >
    <Flex width={1}>
      <Column
        width={[1, null, null, 1 / 3]}
        position="relative"
        display={["none", null, "flex"]}
      >
        <Box width={"120%"} position="absolute" left="-20%" top="0">
          <object
            type="image/svg+xml"
            data="/images/anim/moving-data.svg"
            aria-label={"Remote work girl"}
          />
        </Box>
      </Column>
      <Column
        width={[1, null, null, 2 / 3]}
        pb={["l", null, "xxl"]}
        position="relative"
      >
        <Text as="h2" textStyle="xxxl-bold" color="text04" mb="l">
          Los datos son importantes para tomar decisiones
        </Text>
        <Paragraph
          as="p"
          textStyle="bodyTextBig"
          mb="m"
          color="text01"
          width={[1, null, "80%"]}
        >
          Gracias al diseño podemos mostrar de manera actualizada la evolución
          del coronavirus en España, para que el análisis por parte de terceros
          sea más sencillo, en un contexto en el que la velocidad es primordial.
        </Paragraph>

        <Box
          position="absolute"
          right="-15%"
          top="0"
          width="35%"
          maxWidth="150px"
          display={["none", null, "flex"]}
        >
          <object
            type="image/svg+xml"
            data="/images/anim/men-deciding.svg"
            aria-label={"Remote work girl"}
          />
        </Box>
        <Box display={["flex", null, "none"]}>
          <object
            type="image/svg+xml"
            data="/images/anim/moving-data-mobile.svg"
            aria-label={"Remote work girl"}
          />
        </Box>
      </Column>
    </Flex>
  </Flex>
);
