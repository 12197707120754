import React from "react";
import { MaxWidth, Box, Stack, Text } from "@sqymagma/elements";
import Section from "../Section";
import { BoxedText, StrongGold } from "../Textstyles/Styles";
import BackgroundImage from "gatsby-background-image"
import { useStaticQuery, graphql } from "gatsby"


const Intro = () => {
const imageData = useStaticQuery(graphql`
query {
	fileName: file(relativePath: { eq: "overlay-manifest-section.jpg" }) {
	childImageSharp {
		fluid(quality: 90, maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
		}
	}
	}
}
`)

return (

	<BackgroundImage
	Tag="section"
	fluid={imageData.fileName.childImageSharp.fluid}
	style={{
		backgroundSize: "cover",
		backgroundPosition: "top center",
	}}
	height="100vh"
	css={`
		background-color: ${p => p.theme.color.brand07};
	`}
	>

	<Section position="relative">
		<MaxWidth height="100%">
			<Stack
			alignItems="center"
			justifyContent="space-between"
			width="100%"
			minHeight="65vh"
			pb="xl"
			flexDirection={["column", null, null, "row"]}
			>
			<Box
				width={[1, null, null, 5 / 12, 1 / 2]}
				mb={["l", null, null, null, 0]}
			>
				<Text as="h1" textStyle="giga" color="text06">
					Nuestro <br />
					manifiesto
				</Text>
			</Box>
			<Box
				position={["relative", null, null, "absolute"]}
				right="0"
				top={[0, 0, 0, "50%"]}
				width={["110%", null, null, 7 / 12, 1 / 2]}
				pl={[0, null, null, null, "xl"]}
				css={[
					`{ 
									transform: translateY(0%);
									margin-right: -10%;
									
									@media screen and (min-width: 960px) {
										transform: translateY(-50%);
										margin-right: 0%;
									}
							}`,
				]}
				>
				<BoxedText>
					Una crisis a escala mundial como la que estamos viviendo va a
					afectar a personas y organizaciones de manera muy diferente.
					Muchos negocios en los que la tecnología no es un facilitador
					para adaptarse al nuevo entorno, se van a ver trastocados, y
					en ocasiones, hundidos. Ante esta situación,
					<StrongGold>
						nuestra obligación es tratar de ayudar en todo lo que
						podamos.
					</StrongGold>
				</BoxedText>
			</Box>
			</Stack>
		</MaxWidth>
	</Section>
	</BackgroundImage>
);
};

export default Intro;
